.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  color: white;
}

main {
  background-color: #383e49;
  padding: 2vw;
}
body {
  background-color: #383e49 !important;
}

.App-link {
  color: #61dafb;
}

.fechas {

  overflow: scroll;

}

.fechas button {

  gap: 1.5vh;

  margin: 0.5vw;
}

.fechas-accordion{
  margin: 5%;
}

.hero{
  padding: 2vw;
}

.hero h1{
  color: #fff;
  font-weight: bold;

}

h1{
  color: #fff !important;
  font-weight: bold;
}

h2 h3 h4 h5 h6{
  color: #fff !important;
}

.book p{
  color:#fff !important;
}


