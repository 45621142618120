@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Days+One&family=Montserrat:wght@224;600;700&display=swap');

body {
  margin: 0;
  font-family:'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #383e49;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: 'Bebas Neue'
}

h2{
  color: #fff;
}
